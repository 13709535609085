import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { aboutModule } from '@/store/modules/about';
import { systemModule } from '@/store/modules/system';
var JobInfo = (function (_super) {
    __extends(JobInfo, _super);
    function JobInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JobInfo.prototype.mounted = function () {
        aboutModule.getJobInfo(this.id);
        aboutModule.increaseViewCount(this.id);
    };
    Object.defineProperty(JobInfo.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(JobInfo.prototype, "jobInfo", {
        get: function () {
            return aboutModule.jobInfo;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], JobInfo.prototype, "id", void 0);
    JobInfo = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], JobInfo);
    return JobInfo;
}(Vue));
export default JobInfo;
